import styled from 'styled-components';
import ReactStars from 'react-stars';

import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';
import { YellowTitle, YellowTitleH1 } from '../_common/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 100px;

  @media (max-width: 776px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const GamesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  justify-content: space-between;
`;

const Item = styled.div`
  width: calc(50% - 25px);
  display: flex;

  @media (max-width: 776px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 25px;
  }
`;

const ItemImage = styled.div`
  position: relative;
  width: 260px;
  height: 335px;
  background-size: cover;
  background-position: 50%;
  margin-right: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
  }

  @media (max-width: 776px) {
    width: 100%;
    height: calc(100vw - 30px);
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const Button = styled.div`
  border-radius: 12px 12px 12px 12px;
  background-color: #256d39eb;
  padding: 12px 14px;
  color: #fff;
  box-sizing: border-box;
  height: 39px;
  min-width: 110px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto', Sans-serif;
  font-size: 15px;
  font-weight: 600;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;

  &:hover {
    color: #faff00;
  }
`;

const ItemTexts = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.h3`
  color: #f2fbf2;
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 35px;
  line-height: 1.3em;
  margin-bottom: 15px;
  margin-top: 10px;

  @media (max-width: 776px) {
    font-size: 30px;
  }
`;

const ItemRow = styled.div`
  display: flex;
  align-items: center;
`;

const Rating = styled.div`
  color: #6ec1e4;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
`;

const Description = styled.div`
  color: #f2fbff;
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 600;
  margin-top: 15px;

  @media (max-width: 776px) {
    font-size: 15px;
  }
`;

const Games = ({ setShowRegBlock }) => {
  const words = useSelector((state) => state.words);
  const title = translateField('games_title', 'games', words) || '';
  const subtitle = translateField('games_subtitle', 'games', words) || '';
  const gamesList = useSelector((state) => state.settings?.gamesList) || [];

  return (
    <Container id="games">
      {title && <YellowTitleH1>{title}</YellowTitleH1>}
      {subtitle && <YellowTitle>{subtitle}</YellowTitle>}
      <GamesContainer>
        {gamesList.map((game, i) => (
          <Item onClick={() => setShowRegBlock(true)} key={i}>
            <ItemImage>
              <img src={game.img} alt={translateField(game.meta_alt, 'games', words)} />
              <Button>{translateField('play_now', 'games', words)}</Button>
            </ItemImage>
            <ItemTexts>
              <ItemTitle>{game.title}</ItemTitle>
              <ItemRow>
                <ReactStars size={20} count={10} value={game.star} />
                <Rating>({game.star})</Rating>
              </ItemRow>
              <Description>{translateField(game.text, 'games', words)}</Description>
            </ItemTexts>
          </Item>
        ))}
      </GamesContainer>
    </Container>
  );
};

export default Games;
