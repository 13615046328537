import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { deviceDetect } from 'react-device-detect';
import { fetchData, getInfoRequest, getUserAgent, getWindowSize, getWords, getYourIpRequest } from '../store/actions';

export const useReduxView = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isFirstVisit = localStorage.getItem('user-first-visit');

  const agentData = deviceDetect();

  const data = useSelector((state) => state.api);
  // TODO:  Меняется интервал с 15 на 5
  // const intervalUpdate = useSelector((state) => state.api?.intervalUpdate);
  const intervalUpdate = 15;

  const settings = useSelector((state) => state.settings);
  const words = useSelector((state) => state.words.server);
  const token = localStorage.getItem('user-token');

  // useEffect(() => {
  //   localStorage.setItem('cmd-key', 'A7irRM5nDa35VqziS9bS');
  // }, []);

  useEffect(() => {
    intervalUpdate && token && setInterval(() => dispatch(getInfoRequest()), intervalUpdate * 1000);
  }, [token, intervalUpdate, dispatch]);

  useEffect(() => {
    function updateDimensions() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      return dispatch(
        getWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      );
    }
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('load', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('load', updateDimensions);
    };
  }, [dispatch, settings.rememberState]);

  useEffect(() => {
    let page = pathname === '/' ? '/home' : pathname;
    const fetchAll = async () => {
      await dispatch(getUserAgent(agentData.userAgent ? agentData.userAgent : agentData.ua));
      await dispatch(getYourIpRequest());
      await dispatch(fetchData(page));
    };

    fetchAll().then((res) => res);
  }, [isFirstVisit, agentData.ua, dispatch, pathname, agentData.userAgent]);

  const langLocalStorage = localStorage.getItem('current-lang');
  const browserLang = (navigator.language || navigator.userLanguage).slice(0, 2);

  const choiceLang = useMemo(() => {
    if (langLocalStorage) return langLocalStorage;
    return (data.languages?.includes(browserLang) && browserLang) || data.language;
  }, [langLocalStorage, browserLang, data.languages, data.language]);

  useEffect(() => {
    if (data && !words && data?.loading && !data?.loadingUpdateData) {
      dispatch(getWords(choiceLang));
    }
  }, [data, dispatch, words, choiceLang, data.languages]);

  return !!(data && words);
};
